<template>
  <div class="panel">
    <div id="colorsSection">
      <div class="flex">
        <div>
          <v-select
            v-model="customColors"
            :items="_.values(colorTemplates)"
            label="Select Color Palette"
            item-text="label"
          ></v-select>
        </div>
      </div>
      <div class="flex">
        <br />
        <v-switch
          id="darkModeToggle"
          v-if="customColors !== 'Custom'"
          v-model="invert"
          label="Dark
          Mode"
        />
      </div>

      <div v-if="customColors == 'Custom'">
        <div
          v-for="(color, key) in colorTemplates['Custom'].scheme"
          :key="key"
          class="color-col"
        >
          <label>{{ color.displayName }}</label>
          <picker v-model="color.value"></picker>
        </div>
        <div class="color-col"></div>
        <div class="color-col"></div>
        <div class="color-col"></div>
      </div>
    </div>
    <div id="buttonsSection">
      <div>
        <v-btn color="primary" @click="resetToDefault">Reset To Default</v-btn>
        <v-btn color="success" @click="updateCSS">Save</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// import css
import { Chrome } from "vue-color";
import customCSSFunctions from "@/mixins/customCSSFunctions.js";
import { mapGetters } from "vuex";
const emptyConfig = require("@/assets/jsonScaffolds/MVConfigurationScaffold.json");
const templates = require("@/assets/jsonScaffolds/DefaultCSSTemplates.json");

export default {
  name: "ColorScheme",
  props: {
    saveFunc: { type: Function }
  },
  mixins: [customCSSFunctions],
  data() {
    return {
      MVConfigurations: JSON.parse(JSON.stringify(emptyConfig)),
      customColors: {},
      themeProps: [
        "--header-color",
        "--borders-backgrounds",
        "--primary",
        "--text"
      ],
      colorTemplates: JSON.parse(JSON.stringify(templates)),
      invert: false
    };
  },
  components: {
    picker: Chrome
  },
  methods: {
    updateCSS() {
       
      if (this.customColors == "Custom") {
       this.MVConfigurations.customCSS.colors =
         this.colorTemplates[this.customColors].scheme;
      }
     this.MVConfigurations.customCSS.invert =this.invert;
     this.MVConfigurations.customCSS.templateName =this.customColors;
     this.saveFunc(JSON.parse(JSON.stringify(this.MVConfigurations)));
    },
    resetToDefault() {
       
     this.customColors = "Default";
     this.MVConfigurations.customCSS.templateName = "Default";
     this.MVConfigurations.customCSS.invert = false;
     this.invert = false;
     this.updateCSS();
    }
  },
  computed: {
    ...mapGetters({ MVConfigurationsStored: "MVConfigurations" })
  },
  mounted() {
    if (this.MVConfigurationsStored?.customCSS?.templateName == "Custom") {
      this.colorTemplates["Custom"].scheme =
        this.MVConfigurationsStored.customCSS.colors;
    } else if (
      this.MVConfigurationsStored?.customCSS?.templateName == undefined
    ) {
      this.MVConfigurations.customCSS.templateName = "Default";
    }
    this.customColors = this.MVConfigurationsStored?.customCSS?.templateName;
    this.invert = this.MVConfigurationsStored?.customCSS?.invert ? true : false;
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.color-col {
  display: inline-block;
  width: 33%;
  text-align: center;
  padding: 10px;
}

.color-col > div {
  margin: auto;
}

.color-col > h2 {
  margin-bottom: 0px;
}

#colorsSection,
#buttonsSection {
  text-align: center;
  margin-bottom: 75px;
}

::v-deep .v-btn {
  margin: 5px;
}
</style>
