<template>
  <div>
    <div class="panel">
      <configInput v-model="MVConfigurationsOrdered"></configInput>
    </div>
    <v-btn @click="save" color="primary">Save</v-btn>
  </div>
</template>

<script>
import configInput from "@/components/nonPageComponents/configurationInput";
import { mapGetters } from "vuex";

const emptyConfig = require("@/assets/jsonScaffolds/MVConfigurationScaffold.json");
export default {
  components: {
    configInput: configInput
  },
  props: {
    saveFunc: { type: Function }
  },
  data() {
    return {
      MVConfigurationsOrdered: JSON.parse(JSON.stringify(emptyConfig))
    };
  },
  computed: {
    ...mapGetters({ MVConfigurations: "MVConfigurations" })
  },
  methods: {
    save() {
      this.saveFunc(this.MVConfigurationsOrdered);
    }
  },
  created() {
    this.MVConfigurationsOrdered = this._.cloneDeep(this.MVConfigurations);
  }
};
</script>
