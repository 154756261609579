<template>
  <div class="panel">
    <v-tabs v-model="tab">
      <v-tab to="/configuration/user-info" exact> User Info </v-tab>
      <v-tab to="/configuration/color-scheme"> Colors Scheme </v-tab>
      <v-tab to="/configuration/mvConfigs"> MV Configurations </v-tab>
      <v-tab to="/configuration/hardware"> Hardware </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="/configuration/user-info">
        <div>
          <div style="text-align: center">
            <strong>Avatar</strong>
            <div>
              <v-icon size="120px">mdi-account-circle</v-icon>
            </div>
          </div>
          <div>
            <div>
              <strong>Name</strong>
              <p>{{ userInfo.fullname }}</p>
            </div>
            <div>
              <strong>Email</strong>
              <p>{{ userInfo.email }}</p>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item value="/configuration/color-scheme">
        <ColorScheme :saveFunc="save"></ColorScheme>
      </v-tab-item>
      <v-tab-item value="/configuration/mvConfigs">
        <mvconfigs :saveFunc="save"></mvconfigs>
      </v-tab-item>
      <v-tab-item value="/configuration/hardware">
        <hardware></hardware>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import mvconfigs from "@/components/nonPageComponents/MVConfigurations";
import hardware from "@/components/nonPageComponents/hardware";
import ColorScheme from "@/components/nonPageComponents/ColorScheme";

export default {
  components: {
    mvconfigs,
    hardware,
    ColorScheme
  },
  data() {
    return {
      user: this.$store.getters.userObject,
      tab: undefined
    };
  },
  methods: {
    async save(configs) {
      await this.$api.saveThemeSettings(
        this.$store.getters.userObject.id,
        configs
      );

      this.$root.$emit("setLoading", false);
      this.$store.dispatch("setGlobalAlertState", {
        title: "Saved",
        description: "Your settings were saved!",
        icon: "success"
      });

      this.$store.commit("MVConfigurations", configs);
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userObject;
    }
  },
  async created() {
    const settings = JSON.parse(
      await this.$api.getThemeSettings(this.$store.getters.userObject.id, {
        404: () => {}
      })
    );
    const ordered = {};
    Object.keys(settings)
      .sort()
      .forEach(key => {
        ordered[key] = settings[key];
      });
    this.$store.commit("MVConfigurations", ordered);
  }
};
</script>
