<template>
  <div id="Configuration">
    <div>
      <div class="panel">
        <span v-if="hubAppRunning">
          <div
            v-for="setting in settingsObj.printSettings"
            :key="setting.name"
            class="d-flex"
          >
            <v-select
              :label="setting.name"
              v-model="setting.printer"
              :items="printers"
              item-text="name"
              item-value="name"
              :loading="loading"
              :disabled="loading"
              style="width: 75%"
            />
            <v-select
              label="Tray"
              v-model="setting.bin"
              :items="trays"
              :loading="loading"
              :disabled="loading"
            />
          </div>
          <div class="d-flex">
            <v-select
              label="Payment Device"
              v-model="settingsObj.paymentDevice"
              :items="availableDevices"
              item-text="deviceName"
              return-object
              :loading="loading"
              :disabled="loading"
              no-data-text="No Devices Found"
              @change="getDeviceFunctionality"
            />
            <v-select
              v-if="settingsObj.paymentDevice.type === 'serial'"
              label="Device Type"
              v-model="settingsObj.paymentDevice.u_devicetype"
              :items="deviceTypes"
              :loading="loading"
              :disabled="loading"
              @change="getDeviceFunctionality"
            />
          </div>
        </span>
        <span v-else>
          <h3>
            The hub app was not detected. Please start the app using the desktop
            shortcut or download from
            <a :href="installerPath">here</a>.
          </h3>
          <button @click="listPrinters">Re-Try Hub App Connection</button>
        </span>
      </div>
    </div>
    <span v-if="hubAppRunning">
      <v-btn color="error" v-if="showBackButton" @click="$router.go(-1)"
        >Back</v-btn
      >
      <v-btn color="primary" @click="listPrinters" :disabled="loading"
        >Refresh Printer List</v-btn
      >
      <v-btn color="primary" @click="getAvailableDevices" :disabled="loading">
        Refresh Payment Device List
      </v-btn>
      <v-btn
        v-if="verifiedDevice === false"
        color="primary"
        @click="verifyDevice"
        :disabled="loading || verifyingDevice"
      >
        Verify Device
      </v-btn>
      <v-btn
        color="success"
        @click="save"
        :disabled="loading || !verifiedDevice"
        >Save</v-btn
      >
    </span>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Configuration",
  props: {
    showBackButton: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      hubAppRunning: true,
      installerPath: this.$store.getters.installerPath,
      printers: [],
      defaultName: "",
      settingsObj: {
        printSettings: [
          { name: "Default Printer", printer: "", bin: "Tray 1" },
          { name: "Registration Printer", printer: "", bin: "Tray 1" },
          { name: "Vehicle Details Printer", printer: "", bin: "Tray 1" },
          { name: "Title", printer: "", bin: "Tray 1" }
        ],
        paymentDevice: {}
      },
      trays: ["Tray 1", "Tray 2", "Tray 3", "Tray 4", "Tray 5", "Tray 6"],
      loadingPrinters: false,
      loadingDevices: false,
      availableDevices: [],
      deviceTypes: [],
      usingHMAC: true,
      verifiedDevice: true,
      verifyingDevice: false
    };
  },
  methods: {
    ...mapActions(["setHubConfiguration"]),
    async listPrinters() {
      this.loadingPrinters = true;

      try {
        await this.$hubapp
          .getAllPrinters()
          .then(msg => {
            this.printers = msg;
            this.hubAppRunning = true;
          })
          .catch(e => {
            console.error(e.message);

            if (!e.message.includes("500")) {
              this.hubAppRunning = false;
            } else {
              this.$root.$emit(
                "push-alert",
                "There was a problem getting all printers",
                {
                  color: "error"
                }
              );
            }
          });
      } catch (error) {
        this.hubAppRunning = false;
        this.loadingPrinters = false;
      }

      await this.$hubapp.getHubConfigs().then(msg => {
        this.settingsObj = msg;
        if (this.settingsObj.printSettings === undefined) {
          this.settingsObj.printSettings = [
            { name: "Default Printer", printer: "", bin: "Tray 1" },
            { name: "Registration Printer", printer: "", bin: "Tray 1" },
            { name: "Vehicle Details Printer", printer: "", bin: "Tray 1" },
            { name: "Title Printer", printer: "", bin: "Tray 1" }
          ];
        }
        if (this.settingsObj.paymentDevice === undefined) {
          this.$set(this.settingsObj, "paymentDevice", {
            deviceName: "MSR",
            type: "MSR"
          });
        }
      });
      this.loadingPrinters = false;
    },
    save() {
      this.$store.dispatch("setGlobalAlertState", {
        title: "Are you Sure?",
        description: "Are these changes correct?",
        actions: [
          {
            text: "Confrim",
            color: "primary",
            handler: async () => {
              try {
                await this.setHubConfiguration(this.settingsObj);
                this.$root.$emit("push-alert", "Your settings were saved.", {
                  color: "success"
                });
              } catch (error) {
                console.error(error);
                this.$root.$emit(
                  "push-alert",
                  "There was a problem saving your settings.",
                  {
                    color: "error"
                  }
                );
              } finally {
                this.$store.dispatch("hideGlobalAlert");
              }
            }
          },
          {
            text: "Cancel",
            color: "error",
            handler: () => {
              this.$store.dispatch("hideGlobalAlert");
            }
          }
        ]
      });
    },
    parseUniTermResponse(response, errorText) {
      if (
        response.Resp.code === "DENY" &&
        response.Resp.verbiage === "HMAC auth not in use"
      ) {
        throw new Error("No HMAC");
      } else if (response.Resp.code !== "SUCCESS") {
        this.$root.$emit("push-alert", errorText, {
          color: "error"
        });
        throw new Error("Failed");
      } else {
        return response.Resp.DataBlock;
      }
    },
    async getSerialDevices() {
      const requireAuth = !this.usingHMAC;
      const serialDeviceResponse = await this.$hubapp.queryUniterm(
        {
          u_action: "seriallist",
          u_id: 42,
          u_flags: "DEVICEONLY"
        },
        {},
        requireAuth
      );
      try {
        const data = this.parseUniTermResponse(
          serialDeviceResponse,
          "There was an issue retrieving serial payment devices"
        );
        const array = data.split("\n");
        const serialDevices = [];
        for (let i = 1; i < array.length; i++) {
          const [port, description] = array[i].split(",");
          serialDevices.push({
            deviceName: description,
            u_device: `SER:${port}`,
            type: "serial"
          });
        }
        if (serialDevices.length > 0) {
          this.getDeviceTypes();
        }
        this.availableDevices = this.availableDevices.concat(serialDevices);
      } catch (e) {
        if (e.message === "No HMAC") {
          this.usingHMAC = false;
          return this.getSerialDevices();
        }
      }
    },
    async getHIDDevices() {
      const requireAuth = !this.usingHMAC;
      const hidDevicesResponse = await this.$hubapp.queryUniterm(
        {
          u_action: "hidlist",
          u_id: 42,
          u_flags: "DEVICEONLY"
        },
        {},
        requireAuth
      );
      try {
        const data = this.parseUniTermResponse(
          hidDevicesResponse,
          "There was an issue retrieving HID payment devices"
        );
        const array = data.split("\n");
        const hidDevices = [];
        // Skip the first one as it's just the csv headers
        for (let i = 1; i < array.length; i++) {
          const [deviceType, , model, product] = array[i].split(",");
          hidDevices.push({
            u_devicetype: deviceType,
            deviceModel: model,
            deviceName: product,
            u_device: "HID",
            type: "HID"
          });
        }
        this.availableDevices = this.availableDevices.concat(hidDevices);
      } catch (e) {
        if (e.message === "No HMAC") {
          this.usingHMAC = false;
          return this.getHIDDevices();
        }
      }
    },
    async getAvailableDevices() {
      this.availableDevices = [
        { deviceName: "No Device", type: "None" },
        { deviceName: "MSR", type: "MSR" }
      ];
      this.settingsObj.paymentDevice = {
        deviceName: "No Device",
        type: "None"
      };
      this.loadingDevices = true;
      await this.getSerialDevices();
      await this.getHIDDevices();
      this.loadingDevices = false;
    },
    async getDeviceTypes() {
      const needsAuth = !this.usingHMAC;
      const deviceTypesResponse = await this.$hubapp.queryUniterm(
        {
          u_action: "devicetypes",
          u_id: 42,
          u_flags: "DEVICEONLY"
        },
        {},
        needsAuth
      );
      try {
        const data = this.parseUniTermResponse(
          deviceTypesResponse,
          "There was an issue retrieving device types"
        );
        const array = data.split("\n");
        const possibleDeviceTypes = [];
        for (let i = 1; i < array.length; i++) {
          const [devicetype, , , connectivity] = array[i].split(",");
          const connectivityArr = connectivity.split("|");
          if (connectivityArr.includes("SERIAL")) {
            possibleDeviceTypes.push(devicetype);
          }
        }
        this.deviceTypes = possibleDeviceTypes;
      } catch (e) {
        if (e.message === "No HMAC") {
          this.usingHMAC = false;
          return this.getDeviceTypes();
        }
      }
    },
    async getDeviceFunctionality() {
      const needsAuth = !this.usingHMAC;
      if (
        ["MSR", "No Device"].includes(this.settingsObj.paymentDevice.deviceName)
      ) {
        this.verifiedDevice = true;
        return;
      }
      if (this._.isEmpty(this.settingsObj.paymentDevice.u_devicetype)) {
        this.verifiedDevice = false;
        return;
      }
      this.loadingDevices = true;
      this.verifiedDevice = false;
      const unitermResponse = await this.$hubapp.queryUniterm(
        {
          u_action: "deviceinfo",
          u_device: this.settingsObj.paymentDevice.u_device,
          u_devicetype: this.settingsObj.paymentDevice.u_devicetype,
          u_id: 42,
          u_flags: "DEVICEONLY|DELAYRESPONSE"
        },
        {},
        needsAuth
      );
      if (
        unitermResponse.Resp.code !== "AUTH" ||
        unitermResponse.Resp.u_errorcode !== "SUCCESS"
      ) {
        this.$root.$emit(
          "push-alert",
          "There was an issue retrieving device functionality",
          {
            color: "error"
          }
        );
      } else {
        this.settingsObj.paymentDevice.functionality =
          unitermResponse.Resp.device_functionality.split("|");
        this.verifyDevice();
      }
      this.loadingDevices = false;
    },
    async verifyDevice() {
      const device = this.settingsObj.paymentDevice;
      if (!device.functionality.includes("KEY")) {
        this.verifiedDevice = true;
        return;
      }
      this.verifyingDevice = true;
      this.$root.$emit(
        "push-alert",
        `Please confirm the ${device.deviceName} displays the message`,
        {
          color: "primary"
        }
      );
      const deviceResponse = await this.$hubapp.queryUniterm(
        {
          u_action: "reqconfirm",
          u_id: 42,
          u_flags: "DEVICEONLY|DELAYRESPONSE",
          u_device: device.u_device,
          u_devicetype: device.u_devicetype,
          u_message: "Can you see this?"
        },
        {},
        true
      );
      if (
        deviceResponse.Resp.code === "AUTH" &&
        deviceResponse.Resp.u_errorcode === "SUCCESS"
      ) {
        if (deviceResponse.Resp.u_confirmed === "yes") {
          this.$root.$emit("push-alert", `${device.deviceName} verified`, {
            color: "success"
          });
          this.verifiedDevice = true;
        } else {
          this.$root.$emit("push-alert", `${device.deviceName} not verified`, {
            color: "error"
          });
          this.verifiedDevice = false;
        }
      } else {
        this.$root.$emit(
          "push-alert",
          `There was an error verifying ${device.deviceName}`,
          {
            color: "error"
          }
        );
      }
      this.verifyingDevice = false;
    }
  },
  async mounted() {
    await this.$store.dispatch("getMonetraCreds");
    await this.$hubapp.setMonetraCreds({
      user: this.monetraUser,
      password: this.monetraPass,
      monetraHost: this.$store.state.BaseInfo.config.monetraUrl.host.replace(
        "https://",
        ""
      )
    });
    await this.getAvailableDevices();
    this.listPrinters();
  },
  computed: {
    ...mapGetters(["monetraUser", "monetraPass"]),
    loading() {
      return this.loadingPrinters || this.loadingDevices;
    },
    deviceSelected() {
      const device = this.settingsObj.paymentDevice;
      return (
        !this._.isEmpty(device.u_device) && !this._.isEmpty(device.u_devicetype)
      );
    }
  }
};
</script>
<style scoped>
::v-deep .v-btn {
  margin: 5px;
}

select {
  width: auto;
}
</style>
