<template>
  <div>
    <span v-for="val in value" :key="val.disp">
      <span v-if="val.type == 'checkbox'">
        <v-switch v-model="val.val" :label="val.disp" />
        <br />
      </span>
      <v-text-field
        v-if="val.type == 'text'"
        :label="val.disp"
        v-model="val.val"
      />
      <v-text-field
        v-else-if="val.type == 'number'"
        :label="val.disp"
        v-model="val.val"
        type="number"
        @blur="clearError"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object }
  },
  methods: {
    clearError: function (e) {
      e.target.style.boxShadow = "none";
    }
  }
};
</script>

<style></style>
